import React, {useEffect, useRef, useState} from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Button} from "primereact/button";
import {TabPanel, TabView} from "primereact/tabview";
import LigaTeilnehmerArea from "../liga/LigaTeilnehmerArea";
import LigaspieltagVerwaltenDialog from "./LigaspieltagVerwaltenDialog";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import TurnierQRCode from "../liga/TurnierQRCode";
import StatisticGameDetailDialog from "../statistics/StatisticGameDetailDialog";
import {ToggleButton} from "primereact/togglebutton";
import RundUmsBoardDialog from "../veranstalter/board/RundUmsBoardDialog";
import {OverlayPanel} from "primereact/overlaypanel";
import {Sidebar} from "primereact/sidebar";
import {PrimeIcons} from "primereact/api";
import ConfirmWithQuestionDialog from "../../general/ConfirmWithQuestionDialog";
import LigaspieltagQRCodeSidebar from "./LigaspieltagQRCodeSidebar";
import LigaspieltagGameErgebnisVonHandSidebar from "./LigaspieltagGameErgebnisVonHandSidebar";
import LigaspieltagStreamerArea from "./LigaspieltagStreamerArea";
import LigaAktionenComponent from "./tabs/LigaAktionenComponent";
import ProgressButton from "../../general/ProgressButton";
import NuPlayerAuswahlComponent from "../../nu/NuPlayerAuswahlComponent";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import RefreshButtonComp from "../../RefreshButtonComp";
import {ProgressSpinner} from "primereact/progressspinner";
import {InputText} from "primereact/inputtext";
import ConfirmProgressDialog from "../../general/ConfirmProgressDialog";
import LigaSpieltagSpieleErzeugenHilfeDialog from "./LigaSpieltagSpieleErzeugenHilfeDialog";
import LigaSpieltagBoardauswahlHilfeDialog from "./LigaSpieltagBoardauswahlHilfeDialog";
import LigaSpieltagEDartDetailStatistikDialog from "./LigaSpieltagEDartDetailStatitikDialog";
import LigaspieltagEdartDetailStatisikArea from "./LigaspieltagEdartDetailStatisikArea";
import DHSelectionComponent from "../../general/DHSeclectionCompontent";
import ConfirmDialog from "../../general/ConfirmDialog";
import TournamentAuslosungDialog from "./auslosung/TournamentAuslosungDialog";

let stompClient = undefined;

export default function LigaspieltagDetail({tournamentId, userId}) {

    const filterOP = useRef(null);

    const [autorefresh, setAutorefresh] = useState(true);

    const[boards, setBoards] = useState([]);
    const[showPlayerSelectionArea, setShowPlayerSelectionArea] = useState(false);

    let [tournament, setTournament] = useState(undefined);

    let [games, setGames] = useState([]);
    let [selectedBoardId, setSelectedBoardId] = useState(undefined);

    let [showHeimArea, setShowHeimArea] = useState(true);
    let [showGastArea, setShowGastArea] = useState(true);

    let [showEDartStatistikDetailDialog, setShowEDartStatistikDetailDialog] = useState(false);

    let [showGameSperren, setShowGameSperren] = useState(false);
    let [showGameSperreAufheben, setShowGameSperreAufheben] = useState(false);
    let [showEditDialog, setShowEditDialog] = useState(false);
    let [showSpieltagStartenDialog, setShowSpieltagStartenDialog] = useState(false);
    let [showGameDetailDialog, setShowGameDetailDialog] = useState(false);
    let [showBoardauswahl, setShowBoardauswahl] = useState(false);
    let [showGamesBeiBoardauswahl, setShowGamesBeiBoardauswahl] = useState(true);
    let [showGamesMitErgebnisBeiBoardauswahl, setShowGamesMitErgebnisBeiBoardauswahl] = useState(false);
    let [showRundUmsBoardDialog, setShowRundUmsBoardDialog] = useState(false);
    let [showGastQRCode, setShowGastQRCode] = useState(false);
    let [showGastdatenVerwaltenDialog, setShowGastdatenVerwaltenDialog] = useState(false);
    let [showErgebnisVonHand, setShowErgebnisVonHand] = useState(false);
    let [showAktionen, setShowAktionen] = useState(false);
    let [showBDVNuHomePlayers, setShowBDVNuHomePlayers] = useState(false);
    let [showBDVNuGuestPlayers, setShowBDVNuGuestPlayers] = useState(false);
    let [showStreamViewFullscreen, setShowStreamViewFullscreen] = useState(false);
    let [showNuSendDialog, setShowNuSendDialog] = useState(false);
    let [showNuSyncDialog, setShowNuSyncDialog] = useState(false);
    let [workingStartLiga, setWorkingStartLiga] = useState(false);
    let [workingNuSync, setWorkingNuSync] = useState(false);
    let [workingNuSend, setWorkingNuSend] = useState(false);
    let [bereitsGespielteGamesAnzeigen, setBereitsGespielteGamesAnzeigen] = useState(true);
    let [selectedGameId, setSelectedGameId] = useState(undefined);
    let [selectedType, setSelectedType] = useState(undefined);
    let [selectedTeam, setSelectedTeam] = useState(undefined);

    let [emailGastverein, setEmailGastverein] = useState("");


    let [showSpieleErzeugenDialog, setShowSpieleErzeugenDialog] = useState(false);
    let [spieleErzeugenWorking, setSpieleErzeugenWorking] = useState(false);
    let [showHilfeSpieleErzeugenDialog, setShowHilfeSpieleErzeugenDialog] = useState(false);
    let [showHilfeBoardauswahlDialog, setShowHilfeBoardauswahlDialog] = useState(false);

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
        if( tournament === null || tournament === undefined) {
            return;
        }
        ladeBoards();

    }, [tournament]);

    useEffect(() => {
        if( autorefresh ) {
            connectStomp();
        } else {
            disconnectStomp();
        }
    }, [autorefresh]);

    useEffect(() => {
        return () => {
            disconnectStomp();
        };
    }, []);

    const disconnectStomp = () => {
        if( stompClient !== null && stompClient !== undefined) {
            console.log("Websocket - Chat - disconnect:");
            stompClient.disconnect(() => {});
        }
    }

    const connectStomp = () => {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                console.log("Websocket - connect success");
                const destination = "/topic/event/" + tournamentId;
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, (payload) => {
                    const body = JSON.parse(payload.body);
                    aktionNachLaden(body);
                });
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }


    const ladeBoards = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/getBoards/"+ tournament.eventOperatorId,
            dto => {
                setBoards(dto.boards);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Boards: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Boards: " + error.message);
            }
        );
    }

    const ladeTurnier = (id) => {
        setErrorMessage(undefined);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/ladeligaspieltag/" + id,
            json => {
                aktionNachLaden(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const aktionNachLaden = (json) => {
        let tournament = json.tournamentDto;
        let games = [];
        for (const tmpGame of tournament.tournamentLeagueGameEntities) {
            if( tmpGame.type === "EZ") {
                let heimPlayer = undefined;
                let gastPlayer = undefined;
                if( tmpGame.player1Id !== null && tmpGame.player1Id !== undefined) {
                    heimPlayer = getPlayerById(tournament, tmpGame.player1Id);
                }
                if( tmpGame.player2Id !== null && tmpGame.player2Id !== undefined) {
                    gastPlayer = getPlayerById(tournament, tmpGame.player2Id);
                }
                games.push({gameId: tmpGame.id, heimPlayer: heimPlayer, gastPlayer: gastPlayer,
                    type: tmpGame.type, boardId: tmpGame.boardId, anwurf: tmpGame.anwurf});
            } else if( tmpGame.type === "DP" ) {
                let heimPlayer1 = undefined;
                let heimPlayer2 = undefined;
                let gastPlayer1 = undefined;
                let gastPlayer2 = undefined;
                if( tmpGame.doppelpartner1Player1Id !== null && tmpGame.doppelpartner1Player1Id !== undefined) {
                    heimPlayer1 = getPlayerById(tournament, tmpGame.doppelpartner1Player1Id);
                }
                if( tmpGame.doppelpartner1Player2Id !== null && tmpGame.doppelpartner1Player2Id !== undefined) {
                    heimPlayer2 = getPlayerById(tournament, tmpGame.doppelpartner1Player2Id);
                }
                if( tmpGame.doppelpartner2Player1Id !== null && tmpGame.doppelpartner2Player1Id !== undefined) {
                    gastPlayer1 = getPlayerById(tournament, tmpGame.doppelpartner2Player1Id);
                }
                if( tmpGame.doppelpartner2Player2Id !== null && tmpGame.doppelpartner2Player2Id !== undefined) {
                    gastPlayer2 = getPlayerById(tournament, tmpGame.doppelpartner2Player2Id);
                }
                games.push({gameId: tmpGame.id, heimPlayer1: heimPlayer1, heimPlayer2: heimPlayer2,
                    gastPlayer1: gastPlayer1, gastPlayer2: gastPlayer2, type: tmpGame.type,
                    boardId: tmpGame.boardId, anwurf: tmpGame.anwurf});
            }
        }
        setGames(games);
        setTournament(tournament);
        setEmailGastverein(tournament.emailGastverein);
    }

    const saveEmailGastverein = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.ligaspieltag + "/email/gastverein/save/" + tournamentId,
            {email: emailGastverein},
            () => {
                setInfoMessage("Email wurde gespeichert.");
                ladeTurnier(tournamentId);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            })
    }

    const getPlayerById = (tmpTournament, id) => {
        return tmpTournament.tournamentPlayerEntities.filter(player => player.id === id)[0];
    }

    const gameSperren = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.ligaspieltag + "/game/sperre/speichern/" + selectedGameId,
            {},
            () => {
                setInfoMessage("Game wurde gesperert.");
                setShowGameSperren(false);
                setSelectedGameId(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setShowGameSperren(false);
                setSelectedGameId(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setShowGameSperren(false);
                setSelectedGameId(undefined);
            })
    }

    const gameSperreAufheben = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.ligaspieltag + "/game/sperre/aufheben/" + selectedGameId,
            {},
            () => {
                setInfoMessage("Gamesperre wurde aufgehoben.");
                setShowGameSperreAufheben(false);
                setSelectedGameId(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setShowGameSperreAufheben(false);
                setSelectedGameId(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setShowGameSperreAufheben(false);
                setSelectedGameId(undefined);
            })
    }

    const spieleErzeugen = (bereich) => {
        setErrorMessage(undefined);
        setSpieleErzeugenWorking(true);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.ligaspieltag + "/spieleerzeugen/" + tournamentId + "/" + bereich,
            {},
            () => {
                setInfoMessage("Spiele wurden erzeugt");
                setSpieleErzeugenWorking(false);
                setShowSpieleErzeugenDialog(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSpieleErzeugenWorking(false);
                setShowSpieleErzeugenDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setSpieleErzeugenWorking(false);
                setShowSpieleErzeugenDialog(false);
            })
    }

    const boardFestlegen = (gameId, boardId) => {
        setErrorMessage(undefined);
        let data = {gameId: gameId, boardId: boardId};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.ligaspieltag + "/boardfestlegen/" + tournamentId,
            data,
            () => {
                setInfoMessage("Board wurde gespeichert");
                ladeTurnier(tournamentId);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            })
    }

    const changeAnwurf = (gameId) => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.ligaspieltag + "/anwurf/change/" + gameId,
            {},
            () => {
                setInfoMessage("Anwurf wurde gespeichert");
                ladeTurnier(tournamentId);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            })
    }

    const saveHeimVerwaltetGastdaten = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.ligaspieltag + "/heimverwaltetgastdaten/" + tournament.id,
            {},
            () => {
                setShowGastdatenVerwaltenDialog(false);
                setInfoMessage("Heim verwaltet jetzt Gastdaten...");
                ladeTurnier(tournament.id);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setShowGastdatenVerwaltenDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setShowGastdatenVerwaltenDialog(false);
            }
        );
    }

    const sendToBDVNu = () => {
        setWorkingNuSend(true);
        setErrorMessage(undefined);

        if( !isSpieltagAbgeschlossen())  {
            setShowNuSendDialog(false);
            setWorkingNuSend(false);
            setErrorMessage("Spieltag muss abgeschlossen sein.");
            return;
        }
        if( tournament.tournamentLeagueGameEntities.filter(game => game.anzahlPlayer1 === null || game.anzahlPlayer1 === undefined).length > 0 ) {
            setShowNuSendDialog(false);
            setWorkingNuSend(false);
            setErrorMessage("Bei allen Spielen muss ein Ergebnis vorhanden sein.");
            return;
        }

        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/meeting/send/" + tournament.externMeetingId,
            {},
            () => {
                setInfoMessage("BDV/nu Daten wurden erfolgreich gesendet.");
                setShowNuSendDialog(false);
                setWorkingNuSend(false);
                ladeTurnier(tournamentId);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Senden zu BDV/nu: " + responseNotOk.message);
                setShowNuSendDialog(false);
                setWorkingNuSend(false);
            },
            error => {
                setErrorMessage("Fehler beim Senden zu BDV/nu:" + error.message);
                setShowNuSendDialog(false);
                setWorkingNuSend(false);
            }, 60000
        );
    }
    const sycBDVNuDaten = () => {
        setWorkingNuSync(true);
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/meeting/refresh/cache/" + tournament.externMeetingId,
            {},
            () => {
                setInfoMessage("BDV/nu Daten wurden erfolgreich aktualsiert.");
                setShowNuSyncDialog(false);
                setWorkingNuSync(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Aktualisieren der BDV/nu Daten: " + responseNotOk.message);
                setShowNuSyncDialog(false);
                setWorkingNuSync(false);
            },
            error => {
                setErrorMessage("Fehler beim Aktualisieren der BDV/nu Daten:" + error.message);
                setShowNuSyncDialog(false);
                setWorkingNuSync(false);
            }, 60000
        );
    }

    const getGameFromState = (gameId) => {
        return games.filter(game => game.gameId === gameId)[0];
    }

    const getPlayers = (heim) => {
        let players = [];
        tournament.tournamentPlayerEntities.forEach(player => {
            if( player.idShortSinglePlayer1 === null || player.idShortSinglePlayer1 === undefined ) {
                // sobald idShortSinglePlayer1 handelt es sich um einen Doppelpartner, der wird im Ligaspieltag nur als HILFSKONSTRUKT angelegt und wird
                // nicht zur Auswahl angezeigt.
                if( heim && player.heimmannschaft) {
                    players.push(player);
                }
                if( !heim && !player.heimmannschaft) {
                    players.push(player);
                }
            }
        });
        return players;
    }

    const getDPHeimPlayer = (gameId, playerNumber) => {
        let game = getGameFromState(gameId);
        if( game === undefined) {
            return undefined;
        }
        if( playerNumber === 1) {
            if( game.heimPlayer1 === undefined) {
                return undefined;
            } else {
                return game.heimPlayer1;
            }
        } else {
            if( game.heimPlayer2 === undefined) {
                return undefined;
            } else {
                return game.heimPlayer2;
            }
        }
    }

    const getDPGastPlayer = (gameId, playerNumber) => {
        let game = getGameFromState(gameId);
        if( game === undefined) {
            return undefined;
        }
        if( playerNumber === 1) {
            if( game.gastPlayer1 === undefined) {
                return undefined;
            } else {
                return game.gastPlayer1;
            }
        } else {
            if( game.gastPlayer2 === undefined) {
                return undefined;
            } else {
                return game.gastPlayer2;
            }
        }
    }

    const isSpieltagNeu = () => {
        return tournament.tournamentstatus === "neu";
    }

    const isEdartDetailStatistik = () => {
        return tournament.edartDetailStatistik
    }

    const isSpieltagAbgeschlossen = () => {
        return tournament.tournamentstatus === "abgeschlossen";
    }

    const getGameDPArea = (game) => {
        let darfGastdatenBearbeiten = tournament.ligaspieltagBereich > game.bereich || tournament.ligaspieltagHeimVerwaltetGastdaten;
        let dartGameSperren = tournament.ligaspieltagBereich > game.bereich;
        if( game.gesperrt) {
            let ergebnis = game.anzahlPlayer1 !== null;
            if( ergebnis && !bereitsGespielteGamesAnzeigen) {
                return undefined;
            }
            if( ergebnis && showBoardauswahl && !showGamesMitErgebnisBeiBoardauswahl ) {
                return undefined;
            }
            return <div style={{marginTop:5, marginBottom:20}}>
                <div style={{fontWeight:"bold"}}>Spiel {game.counter}:
                    {ergebnis && tournament.scoringWithDarthelfer &&
                        <Button icon={PrimeIcons.SEARCH_PLUS}
                                onClick={() => {
                                    setSelectedGameId(game.gameId);
                                    setShowGameDetailDialog(true);
                                }}
                                tooltip="Zeige Details" style={{marginLeft:5}}/>
                    }
                    <Button icon={PrimeIcons.PENCIL} style={{marginLeft:5 }}
                            disabled={!isAktuellerUserAdministrator() || isSpieltagAbgeschlossen()}
                            onClick={() => {
                        setShowErgebnisVonHand(true);
                        setSelectedGameId(game.id);
                    }}/>
                    {!ergebnis &&
                        <Button icon={PrimeIcons.SLACK} style={{marginLeft:5}}
                                className={"p-button-danger"}
                                disabled={!isAktuellerUserAdministrator() || isSpieltagAbgeschlossen()}
                                onClick={() => {
                                    setShowGameSperreAufheben(true);
                                    setSelectedGameId(game.id);
                                }}/>
                    }
                </div>
                {!ergebnis && getGameBoardAnzeigeArea(game.id, game.boardDurchAutomatischeBoardvergabeVergeben)}
                {(!showBoardauswahl || (showBoardauswahl && showGamesBeiBoardauswahl)) && <>
                    <div>
                        <div style={{fontWeight: game.anzahlPlayer1 !== null && game.anzahlPlayer1 > game.anzahlPlayer2 ? "bold" : ""}}>
                            {getDPHeimPlayer(game.id, 1).name} / {getDPHeimPlayer(game.id, 2).name}
                        </div>
                        {(game.paarkreuzwertHeim1 || game.paarkreuzwertHeim2)  &&
                            <div style={{marginLeft: 5, marginRight: 5, fontSize: 16, opacity:"0.5"}}>
                                ({game.paarkreuzwertHeim1} / {game.paarkreuzwertHeim2})
                            </div>
                        }
                        <div style={{marginTop:5, marginBottom:5, fontSize:16}}>vs.</div>
                        {(game.paarkreuzwertGast1 || game.paarkreuzwertGast2)  &&
                            <div style={{marginLeft: 5, marginRight: 5, fontSize: 16, opacity:"0.5"}}>
                                ({game.paarkreuzwertGast1} / {game.paarkreuzwertGast2})
                            </div>
                        }
                        <div style={{fontWeight: game.anzahlPlayer1 !== null && game.anzahlPlayer2 > game.anzahlPlayer1 ? "bold" : ""}}>
                            {getDPGastPlayer(game.id, 1).name} / {getDPGastPlayer(game.id, 2).name}
                        </div>
                    </div>
                    {game.anzahlPlayer1 !== null &&
                        <div>
                            Ergebnis: {game.anzahlPlayer1} : {game.anzahlPlayer2}
                        </div>
                    }
                </>
                }
                {showBoardauswahl && (game.anzahlPlayer1 === null || game.anzahlPlayer1 === undefined) && getGameBoardAuswahlArea(game.id, game.boardDurchAutomatischeBoardvergabeVergeben)}
            </div>
        } else {
            return <div style={{marginTop:5, marginBottom:20}}>
                <div style={{fontWeight:"bold"}}>Spiel {game.counter}:
                    {dartGameSperren &&
                        <Button icon={PrimeIcons.SLACK} style={{marginLeft:5}}
                                severity="success"
                                disabled={!isAktuellerUserAdministrator() || isSpieltagAbgeschlossen()}
                                onClick={() => {
                                    setShowGameSperren(true);
                                    setSelectedGameId(game.id);
                                }}/>
                    }
                </div>
                {getGameBoardAnzeigeArea(game.id, game.boardDurchAutomatischeBoardvergabeVergeben)}
                {(!showBoardauswahl || (showBoardauswahl && showGamesBeiBoardauswahl)) &&
                    <div>
                        {!showHeimArea && <><img src="/images/eye_color.png" style={{width:30}}/></>}
                        {showHeimArea && <>
                            {getDPHeimPlayer(game.id, 1) === undefined ?
                                <Button icon={PrimeIcons.PENCIL} onClick={() => {
                                    setShowPlayerSelectionArea(true);
                                    setSelectedGameId(game.id);
                                    setSelectedType("doppel1");
                                    setSelectedTeam("heim");
                                }}/>
                                :
                                <span><Button icon={PrimeIcons.TRASH} onClick={() => savePlayer(undefined, game.id, "doppel1", "heim")} style={{marginRight:5}}/>{getDPHeimPlayer(game.id, 1).name}</span>
                            }
                            <span> / </span>
                            {getDPHeimPlayer(game.id, 2) === undefined ?
                                <Button icon={PrimeIcons.PENCIL} onClick={() => {
                                    setShowPlayerSelectionArea(true);
                                    setSelectedGameId(game.id);
                                    setSelectedType("doppel2");
                                    setSelectedTeam("heim");
                                }}/>
                                :
                                <span>{getDPHeimPlayer(game.id, 2).name}<Button icon={PrimeIcons.TRASH} onClick={() => savePlayer(undefined, game.id, "doppel2", "heim")} style={{marginLeft:5}}/></span>
                            }
                        </>
                        }
                        {(game.paarkreuzwertHeim1 || game.paarkreuzwertHeim2)  &&
                            <div style={{marginLeft: 5, marginRight: 5, fontSize: 16, opacity:"0.5"}}>
                            ({game.paarkreuzwertHeim1} / {game.paarkreuzwertHeim2})
                            </div>
                        }
                        <div style={{marginTop:5, marginBottom:5, fontSize:16}}>vs.</div>
                        {(game.paarkreuzwertGast1 || game.paarkreuzwertGast2)  &&
                            <div style={{marginLeft: 5, marginRight: 5, fontSize: 16, opacity:"0.5"}}>
                                ({game.paarkreuzwertGast1} / {game.paarkreuzwertGast2})
                            </div>
                        }
                        {!showGastArea && <><img src="/images/eye_color.png" style={{width:30}}/></>}
                        {showGastArea && <>
                            {darfGastdatenBearbeiten && getDPGastPlayer(game.id, 1) === undefined &&
                                <Button icon={PrimeIcons.PENCIL} onClick={() => {
                                    setShowPlayerSelectionArea(true);
                                    setSelectedGameId(game.id);
                                    setSelectedType("doppel1");
                                    setSelectedTeam("gast");
                                }}/>
                            }
                            {darfGastdatenBearbeiten && getDPGastPlayer(game.id, 1) !== undefined &&
                                <span>
                                    <Button icon={PrimeIcons.TRASH} onClick={() => savePlayer(undefined, game.id, "doppel1", "gast")} style={{marginRight:5}}/>
                                    {getDPGastPlayer(game.id, 1).name}
                                </span>
                            }
                            {!darfGastdatenBearbeiten && getDPGastPlayer(game.id, 1) !== undefined &&
                                <span><img src="/images/check.png" style={{width: 25, marginLeft:10}} alt="check"/></span>
                            }
                            <span> / </span>
                            {darfGastdatenBearbeiten && getDPGastPlayer(game.id, 2) === undefined &&
                                <Button icon={PrimeIcons.PENCIL} onClick={() => {
                                    setShowPlayerSelectionArea(true);
                                    setSelectedGameId(game.id);
                                    setSelectedType("doppel2");
                                    setSelectedTeam("gast");
                                }}/>
                            }
                            {darfGastdatenBearbeiten && getDPGastPlayer(game.id, 2) !== undefined &&
                                <span>
                                    {getDPGastPlayer(game.id, 2).name}
                                    <Button icon={PrimeIcons.TRASH} onClick={() => savePlayer(undefined, game.id, "doppel2", "gast")} style={{marginLeft:5}}/>
                                </span>
                            }
                            {!darfGastdatenBearbeiten && getDPGastPlayer(game.id, 2) !== undefined &&
                                <span><img src="/images/check.png" style={{width: 25, marginLeft:10}} alt="check"/></span>
                            }
                            </>
                        }
                    </div>
                }
                {showBoardauswahl && getGameBoardAuswahlArea(game.id, game.boardDurchAutomatischeBoardvergabeVergeben)}
            </div>
        }
    }

    const getBoardComponent = () => {
        const tmp = [];
        tmp.push({name: 'Auswahl löschen', code: undefined});
        for (const b of boards) {
            if( b.eventOperatorIdShared )  {
                tmp.push({name: <div>
                        <div>{b.bezeichnung}</div>
                        <div style={{fontSize:12}}>
                            {b.beschreibungShared}
                        </div>
                    </div>, code: b.id});
            } else {
                tmp.push({name: b.bezeichnung, code: b.id});
            }
        }
        return <div key={"key_dh_boards"}>
            <div style={{marginBottom: 5}}>Boardauswahl</div>
            <DHSelectionComponent title="Auswahl" disabled={false} onChange={(id) => setSelectedBoardId(id)} value={selectedBoardId} values={tmp}/>
            <div style={{marginTop: 5}}>Hinweis: Wähle ein Board aus und klicke bei den Spielen die auf diesem Board gespielt werden die Dartscheibe an.</div>
        </div>
    }

    const getGameBoardAnzeigeArea = (gameId, boardDurchAutomatischeBoardvergabeVergeben) => {
        if (games.length === 0) {
            return;
        }
        let game = games.filter(tmpGame => tmpGame.gameId === gameId)[0];
        if( game.boardId === null || game.boardId === undefined || boards.length === 0) {
            return "";
        }
        let board = boards.filter(board => board.id === game.boardId)[0];
        if( board === null || board === undefined) {
            // vermutlich wurde das vergebene board gelöscht.
            return "kein board";
        }
        return <div className="grid" style={{padding: 0, margin: 0, backgroundColor:"red", color:"white", width:250,
                border: "2px solid white",
                borderTopLeftRadius: 10,borderTopRightRadius: 10,}}>
                <div className="col-fixed" style={{padding:0, margin:0, width: 30, textAlign:"left", alignContent:"left"}}>
                    {game.anwurf === "heim" && <img src="/images/dart_single.png" style={{marginLeft:10, height: 20}}/>}
                </div>
                <div className="col">
                    <div style={{padding:0, margin:0, display:"flex", textAlign:"center", justifyContent:"center", verticalAlign:"middle", alignItems:"center"}}>
                        {board.bezeichnung}{boardDurchAutomatischeBoardvergabeVergeben && <><br/>Automatisch vergeben</>}
                        {board.beschreibungShared && <><br/>{board.beschreibungShared}</>}
                    </div>
                </div>
                <div className="col-fixed" style={{padding:0, margin:0, width: 30}}>
                    {game.anwurf === "gast" && <img src="/images/dart_single.png" style={{height: 20}}/>}
                </div>
            </div>
    }

    const getGameBoardAuswahlArea = (gameId, boardDurchAutomatischeBoardvergabeVergeben) => {
        let game = games.filter(tmpGame => tmpGame.gameId === gameId)[0];
        let board = undefined;
        if( game.boardId !== null && game.boardId !== undefined && boards.length > 0) {
            board =  boards.filter(board => board.id === game.boardId)[0];
        }
        return <div>
            <div style={{textAlign:"left", marginTop:10}}>
                {selectedBoardId !== undefined && board === undefined &&
                    <img src="/images/dart_select.png" alt="Board zuweisen" style={{cursor: "pointer", width: 50, margin: 5}} onClick={() => boardFestlegen(gameId, selectedBoardId)}/>
                }
                {board !== undefined && !boardDurchAutomatischeBoardvergabeVergeben &&
                    <img src="/images/delete_color.png" alt="Board entfernen" style={{cursor: "pointer", width: 50, margin: 5}} onClick={() => boardFestlegen(gameId, undefined)}/>
                }
                <Button label={game.anwurf === "heim" ? "Anwurf: Heim" : "Anwurf: Gast"}  style={{height:50}} onClick={() => changeAnwurf(game.gameId)} />
            </div>
            <hr/>
        </div>
    }

    const getPlayerName = (playerId) => {
        if( playerId === null || playerId === undefined) {
            return "";
        }
        if( tournament.tournamentPlayerEntities.length === 0) {
            return "";
        }
        return tournament.tournamentPlayerEntities.filter(player => player.id === playerId)[0].name;
    }

    const getGameEZArea = (game) => {
        let darfGastdatenBearbeiten = tournament.ligaspieltagBereich > game.bereich || tournament.ligaspieltagHeimVerwaltetGastdaten;
        let dartGameSperren = tournament.ligaspieltagBereich > game.bereich;
        if( game.gesperrt) {
            let ergebnis = game.anzahlPlayer1 !== null;
            if( ergebnis && !bereitsGespielteGamesAnzeigen) {
                return undefined;
            }
            if( ergebnis && showBoardauswahl && !showGamesMitErgebnisBeiBoardauswahl ) {
                return undefined;
            }
            return <div style={{marginTop:5, marginBottom:10}}>
                <div style={{fontWeight:"bold"}}>Spiel: {game.counter}
                    {ergebnis && tournament.scoringWithDarthelfer &&
                        <Button icon={PrimeIcons.SEARCH_PLUS}
                                onClick={() => {
                                    setSelectedGameId(game.gameId);
                                    setShowGameDetailDialog(true);
                                }}
                                tooltip="Zeige Details" style={{marginLeft:5}}/>
                    }
                    <Button icon={PrimeIcons.PENCIL} style={{marginLeft:5}}
                            disabled={!isAktuellerUserAdministrator() || isSpieltagAbgeschlossen()}
                            onClick={() => {
                                setShowErgebnisVonHand(true);
                                setSelectedGameId(game.id);
                            }}/>
                    {!ergebnis &&
                        <Button icon={PrimeIcons.SLACK} style={{marginLeft:5}}
                                className={"p-button-danger"}
                                disabled={!isAktuellerUserAdministrator() || isSpieltagAbgeschlossen()}
                                onClick={() => {
                                    setShowGameSperreAufheben(true);
                                    setSelectedGameId(game.id);
                                }}/>
                    }
                </div>
                {!ergebnis && getGameBoardAnzeigeArea(game.id, game.boardDurchAutomatischeBoardvergabeVergeben)}
                {(!showBoardauswahl || (showBoardauswahl && showGamesBeiBoardauswahl)) && <>
                    <div>
                        <span style={{fontWeight: game.anzahlPlayer1 !== null && game.anzahlPlayer1 > game.anzahlPlayer2 ? "bold": ""}}>
                            {getPlayerName(game.player1Id)}
                        </span>
                        {game.paarkreuzwertHeim1 &&
                            <span style={{marginLeft: 5, marginRight: 5, fontSize: 16, opacity:"0.5"}}>
                            ({game.paarkreuzwertHeim1})
                            </span>
                        }
                        <span style={{marginLeft: 5, marginRight: 5, fontSize: 16}}>
                            vs.
                        </span>
                        {game.paarkreuzwertGast1 &&
                            <span style={{marginLeft: 5, marginRight: 5, fontSize: 16, opacity:"0.5"}}>
                            ({game.paarkreuzwertGast1})
                            </span>
                        }
                        <span style={{fontWeight: game.anzahlPlayer1 !== null && game.anzahlPlayer2 > game.anzahlPlayer1 ? "bold": ""}}>
                            {getPlayerName(game.player2Id)}
                        </span>
                    </div>
                    {game.anzahlPlayer1 !== null &&
                        <div>
                            Ergebnis: {game.anzahlPlayer1} : {game.anzahlPlayer2}
                        </div>
                    }
                </>
                }
                {showBoardauswahl && (game.anzahlPlayer1 === null || game.anzahlPlayer1 === undefined) && getGameBoardAuswahlArea(game.id, game.boardDurchAutomatischeBoardvergabeVergeben)}
            </div>
        } else {
            let existPlayer1 = game.player1Id !== null && game.player1Id !== undefined;
            let existPlayer2 = game.player2Id !== null && game.player2Id !== undefined;

            // Bereich wurde noch nicht freigegeben
            return <div style={{marginTop:5, marginBottom:10}}>
                <div style={{fontWeight:"bold"}}>Spiel: {game.counter}
                    {dartGameSperren &&
                        <Button icon={PrimeIcons.SLACK} style={{marginLeft:5}}
                                severity="success"
                                disabled={!isAktuellerUserAdministrator() || isSpieltagAbgeschlossen()}
                                onClick={() => {
                                    setShowGameSperren(true);
                                    setSelectedGameId(game.id);
                                }}/>
                    }
                </div>
                {getGameBoardAnzeigeArea(game.id, game.boardDurchAutomatischeBoardvergabeVergeben)}
                {(!showBoardauswahl || (showBoardauswahl && showGamesBeiBoardauswahl)) &&
                    <div>
                        {!showHeimArea && <><img src="/images/eye_color.png" style={{width:30}}/></>}
                        {showHeimArea && <>
                            {!existPlayer1 &&
                                <Button icon={PrimeIcons.PENCIL} onClick={() => {
                                    setShowPlayerSelectionArea(true);
                                    setSelectedGameId(game.id);
                                    setSelectedType("einzel");
                                    setSelectedTeam("heim");
                                }}/>
                            }
                            {existPlayer1 &&
                                <span>
                                    <Button icon={PrimeIcons.TRASH} onClick={() => savePlayer(undefined, game.id, "einzel", "heim")} style={{marginRight:5}}/>
                                    {getPlayerName(game.player1Id)}
                                </span>
                            }
                        </>
                        }
                        {game.paarkreuzwertHeim1 &&
                            <span style={{marginLeft: 5, marginRight: 5, fontSize: 16, opacity:"0.5"}}>
                            ({game.paarkreuzwertHeim1})
                            </span>
                        }
                        <span style={{marginLeft: 5, marginRight: 5, fontSize: 16}}>
                            vs.
                        </span>
                        {game.paarkreuzwertGast1 &&
                            <span style={{marginLeft: 5, marginRight: 5, fontSize: 16, opacity:"0.5"}}>
                            ({game.paarkreuzwertGast1})
                            </span>
                        }
                        {!showGastArea && <><img src="/images/eye_color.png" style={{width:30}}/></>}
                        {showGastArea && <>
                            {darfGastdatenBearbeiten && !existPlayer2 &&
                                <Button icon={PrimeIcons.PENCIL} onClick={() => {
                                    setShowPlayerSelectionArea(true);
                                    setSelectedGameId(game.id);
                                    setSelectedType("einzel");
                                    setSelectedTeam("gast");
                                }}/>
                            }
                            {darfGastdatenBearbeiten && existPlayer2 &&
                                <span>
                                    {getPlayerName(game.player2Id)}
                                    <Button icon={PrimeIcons.TRASH} onClick={() => savePlayer(undefined, game.id, "einzel", "gast")} style={{marginLeft:5}}/>
                                </span>
                            }
                            {!darfGastdatenBearbeiten && existPlayer2 &&
                                <span><img src="/images/check.png" style={{width: 25, marginLeft:10}} alt="check"/></span>}
                            </>
                        }
                    </div>
                }
                {showBoardauswahl && getGameBoardAuswahlArea(game.id, game.boardDurchAutomatischeBoardvergabeVergeben)}
            </div>
        }
    }

    const isBereichAktuellerBereich = (bereich) => {
        return tournament.ligaspieltagBereich === bereich;
    }

    const getBereichArea = (bereich) => {
        return <div style={{marginTop:10, marginBottom:20}}>
            <div style={{height:40}}>
                {isBereichAktuellerBereich(bereich) &&
                    <ProgressButton text="Spiele erzeugen" onClick={() => setShowSpieleErzeugenDialog(true)} disabled={false} working={spieleErzeugenWorking}/>
                }
            </div>
            <hr/>
        </div>
    }

    const isPhaseGefuellt = () => {
        let returnValue = true;
        let games = tournament.tournamentLeagueGameEntities;
        games.forEach(game => {
            let name = "";
            if( game.bereich === tournament.ligaspieltagBereich) {
                if( game.type === "DP") {
                    let heimPlayer1 = getPlayerById(tournament, game.doppelpartner1Player1Id);
                    let heimPlayer2 = getPlayerById(tournament, game.doppelpartner1Player2Id);
                    let gastPlayer1 = getPlayerById(tournament, game.doppelpartner2Player1Id);
                    let gastPlayer2 = getPlayerById(tournament, game.doppelpartner2Player2Id);
                    if( !heimPlayer1 || !heimPlayer2 || !gastPlayer1 || !gastPlayer2) {
                        returnValue = false;
                    }
                } else {
                    let heimPlayer = getPlayerById(tournament, game.player1Id);
                    let gastPlayer = getPlayerById(tournament, game.player2Id);
                    if( !heimPlayer || !gastPlayer) {
                        returnValue = false;
                    }
                }
            }
        });
        return returnValue;
    }

    const anzahlSpieleZumPlayer = (playerId) => {
        let counter = 0;
        let games = tournament.tournamentLeagueGameEntities;
        games.forEach(game => {
            if( game.bereich === tournament.ligaspieltagBereich) {
                if( game.type === "DP") {
                    if( playerId === game.doppelpartner1Player1Id) {
                        counter++;
                    }
                    if( playerId === game.doppelpartner1Player2Id) {
                        counter++;
                    }
                    if( playerId === game.doppelpartner2Player1Id) {
                        counter++;
                    }
                    if( playerId === game.doppelpartner2Player2Id) {
                        counter++;
                    }
                } else {
                    if( playerId === game.player1Id) {
                        counter++;
                    }
                    if( playerId === game.player2Id) {
                        counter++;
                    }
                }
            }
        });
        return counter;
    }

    const getPhaseZusammenfassung = () => {
        let rows = [];
        let games = tournament.tournamentLeagueGameEntities;
        if( !isPhaseGefuellt() ) {
            rows.push(<div style={{color:"red", marginBottom:20}}>Alle Spiele müssen vollständig mit Spielern gefüllt sein.</div>)
        }
        rows.push(<div style={{marginBottom:20}}><strong>Hinweis: </strong>
            rot markierte Spieler sind in dieser Phase mehr als einmal vorhanden. Dies kann ein Problem sein. Bitte genau prüfen!<br/>
            Sofern Spieler der Gastmannschaft rot markiert sind, spreche noch einmal mit der Gastmannschaft ob ein Fehler in der Aufstellung unterlaufen ist.
        </div>)
        games.forEach(game => {
            let name = [];
            if( game.bereich === tournament.ligaspieltagBereich) {
                if( game.type === "DP") {
                 let heimPlayer1 = getPlayerById(tournament, game.doppelpartner1Player1Id);
                 let heimPlayer2 = getPlayerById(tournament, game.doppelpartner1Player2Id);
                 let gastPlayer1 = getPlayerById(tournament, game.doppelpartner2Player1Id);
                 let gastPlayer2 = getPlayerById(tournament, game.doppelpartner2Player2Id);
                    if(heimPlayer1 ) {
                        name.push(<span style={{color: anzahlSpieleZumPlayer(heimPlayer1.id) > 1 ? "red" : ""}}>{heimPlayer1.name}</span>);
                    }
                    name.push(" / ");
                    if(heimPlayer2 ) {
                        name.push(<span style={{color: anzahlSpieleZumPlayer(heimPlayer2.id) > 1 ? "red" : ""}}>{heimPlayer2.name}</span>);
                    }
                    name.push(" vs. ");
                    if(gastPlayer1 ) {
                        name.push(<span style={{color: anzahlSpieleZumPlayer(gastPlayer1.id) > 1 ? "red" : ""}}>{tournament.ligaspieltagHeimVerwaltetGastdaten ? gastPlayer1.name : "Gastspieler"}</span>);
                    }
                    name.push(" / ");
                    if(gastPlayer2 ) {
                        name.push(<span style={{color: anzahlSpieleZumPlayer(gastPlayer2.id) > 1 ? "red" : ""}}>{tournament.ligaspieltagHeimVerwaltetGastdaten ? gastPlayer2.name : "Gastspieler"}</span>);
                    }
                } else {
                    let heimPlayer = getPlayerById(tournament, game.player1Id);
                    let gastPlayer = getPlayerById(tournament, game.player2Id);
                    if(heimPlayer ) {
                        name.push(<span style={{color: anzahlSpieleZumPlayer(heimPlayer.id) > 1 ? "red" : ""}}>{heimPlayer.name}</span>);
                    }
                    name.push(" vs. ");
                    if(gastPlayer ) {
                        name.push(<span style={{color: anzahlSpieleZumPlayer(gastPlayer.id) > 1 ? "red" : ""}}>{tournament.ligaspieltagHeimVerwaltetGastdaten ? gastPlayer.name : "Gastspieler"}</span>);
                    }
                }
                rows.push(<div style={{marginBottom:5}}><strong>Spiel {game.counter}:</strong> {name}</div>);
            }
        });
        rows.push(<div style={{marginTop:20}}>Möchtest du die Spiele dieser Phase wirklich erzeugen?</div>);
        return rows;
    }

    const getFilterArea = () => {
        return <OverlayPanel ref={filterOP}
                                        showCloseIcon={false} dismissable={true}
                                        style={{height: 350, width: 250}}>
            <div style={{marginTop: 20}}>
                <div>
                    Gespielte Spiele anzeigen:
                </div>
                <div style={{marginTop:10}}>
                    <ToggleButton checked={bereitsGespielteGamesAnzeigen} onLabel="Ja" offLabel="Nein" style={{width:"100%"}}
                                  onChange={() => setBereitsGespielteGamesAnzeigen(!bereitsGespielteGamesAnzeigen)}/>
                </div>
            </div>
            <div style={{marginTop: 20}}>
                <div>
                    Zeige Spieledetails bei Boardauswahl:
                </div>
                <div style={{marginTop:10}}>
                    <ToggleButton checked={showGamesBeiBoardauswahl} onLabel="Ja" offLabel="Nein" style={{width:"100%"}}
                                  onChange={() => setShowGamesBeiBoardauswahl(!showGamesBeiBoardauswahl)}/>
                </div>
            </div>
            <div style={{marginTop: 20}}>
                <div>
                    Bei Boardauswahl, Spiele mit Ergebnis ausblenden:
                </div>
                <div style={{marginTop:10}}>
                    <ToggleButton checked={!showGamesMitErgebnisBeiBoardauswahl} onLabel="Ja" offLabel="Nein" style={{width:"100%"}}
                                  onChange={() => setShowGamesMitErgebnisBeiBoardauswahl(!showGamesMitErgebnisBeiBoardauswahl)}/>
                </div>
            </div>
        </OverlayPanel>
    }

    const ermittleHoechstenBereichAusGames = () => {
        let bereich = 1;
        for (const game of tournament.tournamentLeagueGameEntities) {
            if( game.bereich > bereich) {
                bereich = game.bereich;
            }
        }
        return bereich;
    }

    const isAktuellerUserAdministrator = () => {
        if( tournament.erstellerUserId === userId) {
            return true;
        }
        for (const admin of tournament.administratoren) {
            if (admin.userId === userId) {
                return true;
            }
        }
        return false;
    }

    const getGameArea = () => {
        let cols = [];
        let games = tournament.tournamentLeagueGameEntities;
        if( games === null || games === undefined || games.length === 0) {
            return <div style={{marginTop:30}}>Aktuell keine Spiele vorhanden, Ligaspieltag muss vermutlich erst gestartet werden.</div>
        }
        let hoechsterBereich = ermittleHoechstenBereichAusGames();
        for( let bereich = 1; bereich <= hoechsterBereich ; bereich++) {
            let tmpGames = games.filter(game => game.bereich === bereich);
            let rows = [];
            tmpGames.forEach(game => {
                if( game.type === "EZ") {
                    let row = getGameEZArea(game);
                    if( row !== undefined) {
                        rows.push(row);
                    }
                } else if( game.type === "DP") {
                    let row = getGameDPArea(game);
                    if( row !== undefined) {
                        rows.push(row);
                    }
                } else {
                    rows.push(<div>Fehlerhafter Gametype, bitte Admin verständigen. LeagueGameId: {game.id}</div>);
                }
            })
            if( rows.length > 0 ) {
                let newRows = [getBereichArea(bereich)].concat(rows);
                cols.push(<div className="col-12 md:col-6 lg:col-3">{newRows}</div>)
            }
        }


        return <div className="grid">
            {cols}
        </div>;
    }

    const getTeilnehmerArea = () => {
        let playersHeim = tournament.tournamentPlayerEntities.filter(player => player.heimmannschaft);
        let playersGast = tournament.tournamentPlayerEntities.filter(player => !player.heimmannschaft);

        return <div>
            {tournament.externMeetingId !== null && tournament.externMeetingId !== undefined &&
                <div>
                    <Button label={"Heimspieler auswählen"} style={{marginRight:5, marginTop:5}}
                            disabled={!isAktuellerUserAdministrator() || isSpieltagAbgeschlossen()}
                            onClick={() => setShowBDVNuHomePlayers(true)}/>
                    <Button label={"Gastspieler auswählen"} style={{marginRight:5, marginTop:5}}
                            disabled={!isAktuellerUserAdministrator() || isSpieltagAbgeschlossen()}
                            onClick={() => setShowBDVNuGuestPlayers(true)}/>
                </div>
            }
            {(tournament.externMeetingId === null || tournament.externMeetingId === undefined) && <div style={{color:"red", marginTop:10}}>
                Achtung! Beim Erfassen der Spieler musst du wählen ob dieser für die Heim- oder Gastmannschaft spielt.
            </div>
            }
            <div>
                <LigaTeilnehmerArea liga={tournament} players={playersHeim} showEditButton={tournament.externMeetingId === null || tournament.externMeetingId === undefined} extendedLabel="Heimmannschaft" readonly={false} getUsernamesByUserId={() => ""}
                                    showExternPersonId={true}
                                    callBackRefresh={() => ladeTurnier(tournamentId)}
                                    isVerschiebenErlaubt={true}
                                    showUsernames={false}
                                    isAdmin={isAktuellerUserAdministrator()}/>
            </div>
            <div style={{marginTop:30}}>
                <LigaTeilnehmerArea liga={tournament} players={playersGast} showEditButton={false} extendedLabel="Gastmannschaft" readonly={false} getUsernamesByUserId={() => ""}
                                        callBackRefresh={() => ladeTurnier(tournamentId)}
                                        isVerschiebenErlaubt={true}
                                        showUsernames={false}
                                        isAdmin={isAktuellerUserAdministrator()}/>
            </div>
        </div>
    }

    const getErgebnis = () => {
        let heim = 0;
        let gast = 0;
        for (const tmpGame of tournament.tournamentLeagueGameEntities) {
            if( tmpGame.anzahlPlayer1 !== null && tmpGame.anzahlPlayer1 !== undefined ) {
                if( tmpGame.anzahlPlayer1 > tmpGame.anzahlPlayer2) {
                    heim = heim + 1;
                } else if( tmpGame.anzahlPlayer1 < tmpGame.anzahlPlayer2) {
                    gast = gast + 1;
                }
            }
        }
        return heim + ":" + gast;
    }

    const savePlayer = (playerId, gameId, type, team) => {
        setErrorMessage(undefined);
        let data = {playerId: playerId, gameId: gameId, type: type, team: team};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.ligaspieltag + "/player/save/" + tournamentId,
            data,
            () => {
                if( playerId === null || playerId === undefined) {
                    setInfoMessage("Spieler wurde gelöscht.");
                } else {
                    setInfoMessage("Spieler wurde gespeichert.");
                }
                ladeTurnier(tournamentId);
                setShowPlayerSelectionArea(false);
                setSelectedGameId(undefined);
                setSelectedType(undefined);
                setSelectedTeam(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setShowPlayerSelectionArea(false);
                setSelectedGameId(undefined);
                setSelectedType(undefined);
                setSelectedTeam(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setShowPlayerSelectionArea(false);
                setSelectedGameId(undefined);
                setSelectedType(undefined);
                setSelectedTeam(undefined);
            }
        );
    }

    const getPlayerSelectionArea = () => {
        let players = getPlayers(selectedTeam === "heim")
        let rows = [];
        let color = true;
        for (const player of players) {
            rows.push(<div key={"key_player_" + player.id}
                           style={{textAlign:"left", border:"1px solid #ddd", fontSize:20, padding:10, cursor:"pointer", backgroundColor: color ? "#f2f2f2" : "white"}}
                           onClick={() => savePlayer(player.id, selectedGameId, selectedType, selectedTeam)}>
                {player.name}
            </div>);
            color = !color;
        }
        return <Sidebar visible={true} position="left" style={{width:400}} onHide={() => {
            setShowPlayerSelectionArea(false);
            setSelectedGameId(undefined);
            setSelectedType(undefined);
            setSelectedTeam(undefined);
        }}>
            <div style={{marginBottom:20}}><Button label="Bereich schliessen" onClick={() => {
                setShowPlayerSelectionArea(false);
                setSelectedGameId(undefined);
                setSelectedType(undefined);
                setSelectedTeam(undefined);
            }}/></div>
            <div style={{textAlign:"left", fontWeight:"bold", paddingBottom:20, fontSize:20, }}>Spielerauswahl</div>
            {rows}
        </Sidebar>
    }

    const existierenNuErsatzspieler = () => {
        return tournament.tournamentPlayerEntities.some(p => p.externPersonId === "-2" || p.externPersonId === "-4");
    }

    if( tournament === undefined) {
        return <div style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
            <ProgressSpinner style={{width: 100, height: 100}} />
        </div>
    }

    if( showStreamViewFullscreen ) {
        return <LigaspieltagStreamerArea tournament={tournament} showFullScreen={showStreamViewFullscreen} onFullScreenChange={() => setShowStreamViewFullscreen(!showStreamViewFullscreen)}/>
    }

    return <div style={{backgroundColor: "white", color: "black", padding: 20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div>
            <h3>Ligaspieltag: {tournament.bezeichnung}</h3>
            <div>Ergebnis: {getErgebnis()}</div>
            <div style={{marginTop: 20}}>
                <RefreshButtonComp text="Auto" autorefresh={autorefresh} onRefresh={() => ladeTurnier(tournamentId)} shutdownminutes={600}
                                   onAutoRefresh={(schalter) => {
                                       setAutorefresh(schalter);
                                   }}/>
            </div>
        </div>
        {isSpieltagNeu() &&
            <div style={{marginTop: 10, color:"red", fontWeight:"bold"}}>
                Der Spieltag ist noch nicht gestartet. Gehe zum Starten auf Details -> Spieltag starten
            </div>
        }
        <div style={{marginTop: 10}}>
            <Button className="p-button-danger" label="-> Hilfe - Spiele erzeugen" onClick={() => setShowHilfeSpieleErzeugenDialog(true)}/>
        </div>
        <TabView style={{width: "100%", paddingTop: 10, paddingLeft: 10, paddingRight: 10}}>
            <TabPanel header="Games">
                {isAktuellerUserAdministrator() && !isSpieltagAbgeschlossen() && isEdartDetailStatistik() && <div style={{marginBottom:20}}>
                    <Button label="E-Dart Detaileingabe" onClick={() => setShowEDartStatistikDetailDialog(true)} />
                </div>
                }
                {isAktuellerUserAdministrator() && !isSpieltagAbgeschlossen() && <>
                    {tournament.ligaspieltagHeimVerwaltetGastdaten &&
                        <div style={{marginBottom: 10}}>
                            <span>Bereiche für das versteckte Setzen ausblenden.</span><br/>
                            <Button label="Heimbereich"
                                    className={showHeimArea ? "p-button-primary" : "p-button-secondary"}
                                    onClick={() => setShowHeimArea(!showHeimArea)} />
                            <Button label="Gastbereich"
                                    className={showGastArea ? "p-button-primary" : "p-button-secondary"}
                                    onClick={() => setShowGastArea(!showGastArea)} />
                        </div>
                    }
                    <div style={{marginBottom: 20}}>
                        <ToggleButton style={{height: 40, marginTop: 5, marginRight: 5, width: 200}} checked={showBoardauswahl} offLabel="Boardauswahl anzeigen" onLabel="Boardauswahl ausblenden"
                                      onChange={() => {
                                          setSelectedBoardId(undefined);
                                          setShowBoardauswahl(!showBoardauswahl);
                                      }}/>
                        <i className="pi pi-filter" style={{marginTop: 5, width: 40, height: 40, fontSize: 30, cursor:"pointer"}}
                           onClick={(e) => filterOP.current.toggle(e)}/>
                        <Button className="p-button-danger" label="-> Hilfe - Boardauswahl" onClick={() => setShowHilfeBoardauswahlDialog(true)} style={{height: 40}}/>
                        {showBoardauswahl &&
                            <div style={{marginTop: 30}}>
                                {getBoardComponent()}
                            </div>
                        }
                    </div>
                </>
                }
                {getGameArea()}
            </TabPanel>
            <TabPanel header="Teilnehmer">
                {getTeilnehmerArea()}
            </TabPanel>
            <TabPanel header="Links">
                <TurnierQRCode eventOperatorId={tournament.eventOperatorId} tournamentId={tournament.id} tournamentSystem={tournament.tournamentstatus} onlineTournament={tournament.online}
                               bezeichnung={tournament.bezeichnung}/>
            </TabPanel>
            {isEdartDetailStatistik() &&
                <TabPanel header="E-Dart Statistik">
                    <LigaspieltagEdartDetailStatisikArea tournamentId={tournament.id} players={tournament.tournamentPlayerEntities} games={tournament.tournamentLeagueGameEntities} publicView={false}/>
                </TabPanel>
            }
            <TabPanel header="Streamer">
                <LigaspieltagStreamerArea tournament={tournament} showFullScreen={showStreamViewFullscreen} onFullScreenChange={() => setShowStreamViewFullscreen(!showStreamViewFullscreen)}/>
            </TabPanel>
            <TabPanel header="Details" disabled={!isAktuellerUserAdministrator()}>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-6">
                        <div className="grid">
                            <div className="col-fixed" style={{width: 200}}>Turnierstatus:</div>
                            <div className="col">{tournament.tournamentstatus}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 200}}>Startzeitpunkt:</div>
                            <div className="col">{tournament.beginnMitUhrzeitString}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 200}}>Heimmannschaft:</div>
                            <div className="col">{tournament.mannschaftHeim}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 200}}>Gastmannschaft:</div>
                            <div className="col">{tournament.mannschaftGast}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 200}}>Spieltagmodus:</div>
                            <div className="col">{tournament.ligaspieltagModus}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 200}}>Paarkreuzsystem:</div>
                            <div className="col">{tournament.ligaspieltagPaarkreuzsystemId ?
                                tournament.ligaspieltagPaarkreuzsystemBezeichnung
                            : <div style={{display:"flex", alignContent:"center", alignItems:"center"}}>
                                    <div>
                                        <img src="/images/ausrufezeichen.png" style={{height: 20, marginRight: 10}}/>
                                    </div>
                                    <div>
                                        Kein Paarkreuzsystem ausgewählt.<br/>
                                        Sofern gewünscht vor dem Starten des Spieltags unter 'Bearbeiten' einstellen.
                                    </div>
                                </div>}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 200}}>Ligaspieltag-Extern:</div>
                            <div className="col">{tournament.externMeetingId ? "ja" : "nein"}</div>
                        </div>
                        {tournament.externMeetingId !== null && tournament.externMeetingId !== undefined && <>
                            <div className="grid">
                                <div className="col-fixed" style={{width: 200}}>Wurde an externes System übermittelt:</div>
                                <div className="col">{tournament.externConfirmed ? "ja" : "nein"}</div>
                            </div>
                            <div className="grid">
                                <div className="col-fixed" style={{width: 200}}>Infosmails beim Übermitteln verschickt:</div>
                                <div className="col">{tournament.infoMailSent ? "ja" : "nein"}</div>
                            </div>
                            <div>
                                <div className="grid" style={{marginTop: 20, marginBottom: 10}}>
                                    <div className="col" style={{width: 200}}>Email Gastverein:<br/>
                                        (nur notwendig sofern der Gastverein eine Zusammenfassung per Mail möchte.)<br/>
                                        (Angabe der Emailadresse muss vor dem Übermitteln an nu eingetragen werden.)
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col" style={{width: 200}}>
                                        <InputText value={emailGastverein} style={{width: 300}}
                                                   onChange={(e) => setEmailGastverein(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col" style={{width: 200}}>
                                        <Button label="Email speichern" icon={PrimeIcons.SAVE}
                                                onClick={() => saveEmailGastverein()} style={{width: 200}}/>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                        </>
                        }
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-6" style={{padding: 20}}>
                            <div className="grid">
                                <div className="col">
                                <Button label="Bearbeiten" icon="pi pi-pencil"
                                            onClick={() => setShowEditDialog(true)} style={{width: 200}}/>
                                </div>
                            </div>
                            {tournament.externMeetingId !== null && tournament.externMeetingId !== undefined && <>
                                <div className="grid">
                                    <div className="col">
                                        <div style={{marginTop: 30, width: 300}}>
                                            <ProgressButton text="BDV/nu Synchronisation" icon={PrimeIcons.SYNC} working={workingNuSync}
                                                            onClick={() => setShowNuSyncDialog(true)}/>
                                        </div>
                                        <div style={{marginTop: 10, marginBottom: 30, width: 300}}>
                                            <ProgressButton text="BDV/nu Ergebnis senden" icon={PrimeIcons.SYNC} working={workingNuSend}
                                                            onClick={() => setShowNuSendDialog(true)}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }
                            <div style={{marginTop: 10}}>
                                <Button label="Aktionen" icon={PrimeIcons.BOLT}
                                        onClick={() => setShowAktionen(true)} style={{width: 200}}/>
                            </div>
                            {tournament.tournamentstatus === "neu" &&
                                <div style={{marginTop: 10}}>
                                    <Button icon="pi pi-align-justify" label="Spieltag starten"
                                            onClick={() => setShowSpieltagStartenDialog(true)} style={{width: 200}}/>
                                </div>
                            }
                            <div style={{marginTop: 10}}>
                                <Button label="Rund ums Board" icon="pi pi-book"
                                        onClick={() => setShowRundUmsBoardDialog(true)} style={{width: 200}}/>
                            </div>
                            {!tournament.ligaspieltagHeimVerwaltetGastdaten && <>
                                <div style={{marginTop: 30}}>
                                    <Button label="QR Code für Gastverein" icon={PrimeIcons.FILE_EDIT}
                                            onClick={() => setShowGastQRCode(true)} style={{width: 200}}/>
                                </div>
                                <div style={{marginTop: 10}}>
                                    <Button label="Heimmannschaft verwaltet Gastdaten" icon={PrimeIcons.BOOK}
                                            onClick={() => setShowGastdatenVerwaltenDialog(true)}/>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </TabPanel>
        </TabView>
        {getFilterArea()}
        {showEditDialog && <LigaspieltagVerwaltenDialog tournamentId={tournament.id}
                                                        callBackRefresh={() => ""}
                                                        callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                        callBackOnClose={() => setShowEditDialog(false)}/>
        }
        {showAktionen && <LigaAktionenComponent showAsSidebar={true} onError={(message) => setErrorMessage(message)}
                                                onInfo={(message) => setInfoMessage(message)}
                                                onClearMessages={() => setErrorMessage(undefined)}
                                                callBackRefresh={() => ladeTurnier(tournamentId)}
                                                callBackCloseDialog={() => console.log("callBackCloseDialog nicht unterstützt")}
                                                proTournament={tournament}
                                                onClose={() => setShowAktionen(false)}
                                                callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                callBackLoadNewTournament={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
        />}
        {showSpieltagStartenDialog && <TournamentAuslosungDialog tournament={tournament}
                                                                 onRefresh={() => ladeTurnier(tournamentId)}
                                                                 onClose={() => setShowSpieltagStartenDialog(false)}/>
        }
        {showNuSyncDialog &&
            <ConfirmProgressDialog visible={true}
                                   working={workingNuSync}
                                   callBackOnNein={() => setShowNuSyncDialog(false)}
                                   callBackOnJa={() => sycBDVNuDaten()}
                                   header="BDV/nu Synchronisation"
                                   textOben="Hier kannst du die Details, wie Spieler usw, vom BDV/Nu Server zu Darthelfer synchronisieren. Dies ist normal nur notwendig, wenn keine Spieler angezeigt werden oder evtl. Spieler nachgemeldet wurden."
                                   textUnten="Möchtest du Synchronistaion wirklich starten?"/>
        }
        {showNuSendDialog &&
            <ConfirmProgressDialog visible={true}
                                   working={workingNuSend}
                                   callBackOnNein={() => setShowNuSendDialog(false)}
                                   callBackOnJa={() => sendToBDVNu()}
                                   header="BDV/nu Ergebnis senden"
                                   textOben={tournament.externConfirmed ? <div style={{color:"red"}}>Es wurde bereits an das externe System übermittelt und die Übermittlung wurde bestätigt. Wir empfehlen nicht erneut zu übertragen.</div> : ""}
                                   textUnten={<div>
                                       { existierenNuErsatzspieler() &&
                                           <div style={{marginBottom:20, fontWeight:"bold", color:"red"}}>
                                               Highlights von Ersatzspielern, also keine richtigen Personen, werden nicht an nu übertragen.<br/>
                                               Du hast Ersatzspieler verwendet. Sofern du diese danach in nu zu einer 'richtigen Person' änderst, kannst du Highlights von Hand in nu nachtragen.
                                           </div>
                                       }
                                       <div>Möchtest du das Ergebnis an nu senden?</div>
                                   </div>}/>
        }
        {showGameDetailDialog &&
            <StatisticGameDetailDialog
                callBackDialogClose={() => {
                    setShowGameDetailDialog(false);
                    setSelectedGameId(undefined);
                }}
                visible={true} gameId={selectedGameId} gameType={tournament.gameType}/>
        }
        {showRundUmsBoardDialog && <RundUmsBoardDialog eventOperatorId={tournament.eventOperatorId} onClose={() => setShowRundUmsBoardDialog(false)}/>}
        {showPlayerSelectionArea && getPlayerSelectionArea()}
        {showGastdatenVerwaltenDialog &&
            <ConfirmWithQuestionDialog
                textOben="Gastdaten sollten über den QR Code vom Gastverein selbst eigetragen werden, die Daten beinhalten die Teilnehmer sowie die Aufstellung.
Sofern die Gastmannschaft dies nicht möchte, verwaltet die Heimmannschaft im Auftrag der Gastmannschaft die Daten.
Nachdem du diese Einstellung vorgenommen hast, kann diese nicht mehr rückgängig gemacht werden.
Der QR Code für die Gastmannschaft verliert seine Gültigkeit und die Eingabe der Daten kann nicht mehr durch die Gastmannschaft erfolgen."
                textUnten="Bist du dir sicher ob du diese Aktion durchführen möchtest?"
                visible={true}
                callBackOnNein={() => setShowGastdatenVerwaltenDialog(false)}
                callBackOnJa={() => saveHeimVerwaltetGastdaten()}
                header="Gastdaten verwalten"/>
        }
        {showGastQRCode && <LigaspieltagQRCodeSidebar tournamentId={tournament.id} onClose={() => setShowGastQRCode(false)}/>}
        {showErgebnisVonHand && <LigaspieltagGameErgebnisVonHandSidebar gameId={selectedGameId}
                                                                        onClose={(load) => {
                                                                            setSelectedGameId(undefined);
                                                                            setShowErgebnisVonHand(false);
                                                                        }
                                                                        }/>}
        {showSpieleErzeugenDialog &&
            <ConfirmProgressDialog header="Spiele erzeugen?"
                                   working={spieleErzeugenWorking}
                                   showJaButton={isPhaseGefuellt()}
                                   textOben={<div style={{color: "red", fontWeight: "bold"}}>Bitte prüfe die Eingaben zu den Spielen genau, nachdem du die Spiele erzeugt hast kannst du nichts mehr
                                       daran ändern.</div>}
                                   textUnten={getPhaseZusammenfassung()}
                                   visible={true}
                                   callBackOnNein={() => setShowSpieleErzeugenDialog(false)} callBackOnJa={() => spieleErzeugen(tournament.ligaspieltagBereich)}/>
        }
        {showBDVNuHomePlayers && <NuPlayerAuswahlComponent externMeetingId={tournament.externMeetingId}
                                                           tournamentId={tournament.id}
                                                           qrCode={undefined}
                                                           tournamentPlayers={tournament.tournamentPlayerEntities}
                                                           showGuestPlayers={false}
                                                           onClose={() => setShowBDVNuHomePlayers(false)}
                                                           onRefresh={() => ""}/>}
        {showBDVNuGuestPlayers && <NuPlayerAuswahlComponent externMeetingId={tournament.externMeetingId}
                                                            tournamentId={tournament.id}
                                                            qrCode={undefined}
                                                            showGuestPlayers={true}
                                                            tournamentPlayers={tournament.tournamentPlayerEntities}
                                                            onClose={() => setShowBDVNuGuestPlayers(false)}
                                                            onRefresh={() => ""}/>}

        {showHilfeSpieleErzeugenDialog && <LigaSpieltagSpieleErzeugenHilfeDialog onClose={() => setShowHilfeSpieleErzeugenDialog(false)}/>}
        {showHilfeBoardauswahlDialog && <LigaSpieltagBoardauswahlHilfeDialog onClose={() => setShowHilfeBoardauswahlDialog(false)}/>}
        {showEDartStatistikDetailDialog && <LigaSpieltagEDartDetailStatistikDialog
            tournamentId={tournament.id} games={tournament.tournamentLeagueGameEntities}
            players={tournament.tournamentPlayerEntities}
            bereich={tournament.ligaspieltagBereich}
            onClose={() => setShowEDartStatistikDetailDialog(false)}/>}

        {showGameSperreAufheben && <ConfirmWithQuestionDialog header={"Sperre aufheben..."}
                                                              textOben="Nach dem Erzeugen der Spiele soll nur im absoluten Notfall noch mal eine Änderung am Spiel vorgenommen werden."
                                                              textUnten="Soll die Sperre für das Spiel aufgehoben werden, so dass du Spieler neu in dieses Spiel eintragen kannst?"
                                                              visible={true} callBackOnNein={() => setShowGameSperreAufheben(false)} callBackOnJa={() => gameSperreAufheben()}/>}
        {showGameSperren && <ConfirmDialog header={"Sperren..."}
                                                              textOben="Möchtest du das Spiel sperren?"
                                                              visible={true} callBackOnNein={() => setShowGameSperren(false)} callBackOnJa={() => gameSperren()}/>}
    </div>
}
